<template>
  <div>
    <div>
      <div class="attendance-container">
        <div class="attendance-status">
          <i class="bi bi-person-plus"></i> 출석 날짜:
        </div>
        <div class="attendance-select">
          <select
            class="form-control"
            id="holiday"
            name="holiday"
            v-model="holiday"
            @change="getGradeGroupList(this)"
          >
            <option id="" value="00">날짜를 선택하세요.</option>
            <option
              v-for="daylist in dayLists"
              :key="daylist.dday"
              v-bind:value="daylist.dday"
              :id="daylist.dday"
            >
              {{ daylist.dday }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!--  출석 인원 -->
    <div class="container">
      <div class="row">
        <div class="col">
          <div v-for="item in gradegrouplist" :key="item.grade">
            <button
              type="button"
              :class="{
                'btn btn-outline-primary btn-lg btn-block':
                  selectedGrade !== item.grade,
                'btn btn-outline-primary btn-lg btn-block btn-primary':
                  selectedGrade === item.grade,
              }"
              style="margin-bottom: 10px"
              @click="getMgmtAllList(item.grade, 1)"
            >
              {{ item.gradename }}
            </button>
            <!-- <button type="button" class="btn btn-secondary btn-lg btn-block">Block level button</button> -->
          </div>
        </div>
        <div class="col">
          <div v-for="list in manageLists" :key="list.no">
            <button
              type="button"
              v-if="list.attendyn == 'Y'"
              class="btn btn-success btn-lg btn-block"
              style="margin-bottom: 10px"
              @click="showModal(list)"
            >
              {{ list.name }}
            </button>
            <button
              v-else
              type="button"
              :class="{
                btn: true,
                'btn-lg': true,
                'btn-block': true,
                'btn-light': cancelAttendyn == true,
                'btn-outline-success': cancelAttendyn == false,
              }"
              style="margin-bottom: 10px"
              @click="showModal(list)"
            >
              {{ list.name }}
            </button>
            <!-- <button type="button" class="btn btn-secondary btn-lg btn-block">Block level button</button> -->
          </div>
        </div>
        <!-- profile -->
        <div class="col">
          <div>
            <div class="card text-white bg-info mb-3" style="max-width: 18rem">
              <div class="card-header" style="text-align: center">
                <h3 class="card-title" style="color: white; font-weight: bold">
                  전달사항
                </h3>
              </div>
              <div class="card-body" style="background: white; color: black">
                <tbody>
                  <tr v-for="list in nlists" v-bind:key="list.no">
                    <td
                      style="color: darkred; font-size: 20px; font-weight: 500"
                      v-html="list.contents.replace(/\n/g, '<br>')"
                    ></td>
                  </tr>
                </tbody>

                <h5 class="card-title"></h5>
                <p class="card-text"></p>
              </div>
            </div>

            <!-- <button type="button" class="btn btn-secondary btn-lg btn-block" style="margin-bottom: 20px;" @click="checkinOkay(studentlist)">출석했습니다.</button> -->
            <!-- <div class="card text-white bg-warning mb-3" style="max-width: 18rem;">
              <div class="card-header" style="text-align: center;"><h1 class="card-title" style="color: black;font-weight: bold;">{{studentlist.name}}</h1></div>
              <div class="card-body" style="background: white; color: black;">
                <img v-if="studentlist.photo !== null " :src="`https://manna.dongan1718.com/upload/photo/${studentlist.photo}`" class="card-img-top" alt="">
                <img v-else src="../assets/noimage.png" class="card-img-top" alt="">
                <h5 class="card-title">반갑습니다!</h5>
                <p class="card-text"></p>
              </div>
            </div> -->
          </div>
        </div>
        <!-- confirm -->

        <div
          class="modal fade"
          id="confrimModal"
          tabindex="-1"
          aria-labelledby="confrimModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="confrimModalLabel">
                  {{ itempopup.gradename }} {{ itempopup.name }}
                </h2>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <!-- 출석 체크 -->
                    <!-- <div class="col-8"></div> -->
                    <!-- <div class="col-8" v-if="itempopup.attendyn == 'Y'" style="font-size: 20px;">출석을 취소하시겠습니까?</div> -->
                    <!-- <div class="col-8" v-else style="font-size: 20px;">출석을 등록하시겠습니까? </div> -->
                    <div
                      class="col-12"
                      style="padding-bottom: 13px; text-align: right"
                    >
                      <button
                        type="button"
                        class="btn btn-danger"
                        v-if="itempopup.attendyn == 'Y'"
                        @click="getGradeStudent(itempopup)"
                      >
                        예배 참석을 취소합니다
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-else
                        @click="getGradeStudent(itempopup)"
                      >
                        예배에 참석합니다
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <!-- 이미지 -->
                      <div
                        class="card text-white bg-warning mb-3"
                        style="max-width: 18rem"
                      >
                        <div
                          class="card-header"
                          style="text-align: center; color: black"
                        >
                          {{ itempopup.name }}님 환영합니다.
                        </div>
                        <div
                          class="card-body"
                          style="background: white; color: black"
                        >
                          <img
                            v-if="itempopup.photo2 !== null"
                            :src="`https://manna.dongan1718.com/upload/photo/${itempopup.photo2}`"
                            class="card-img-top"
                            alt=""
                            id="myImage1"
                          />
                          <img
                            v-else-if="itempopup.photo !== null"
                            :src="`https://manna.dongan1718.com/upload/photo/${itempopup.photo}`"
                            class="card-img-top"
                            alt=""
                            id="myImage2"
                          />
                          <img
                            v-else
                            src="../assets/noimage.png"
                            class="card-img-top"
                            alt=""
                          />
                          <h5 class="card-title"></h5>
                          <p class="card-text"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="container">
                  <div class="row">
                    <div class="col-sm">
                      <button
                        type="button"
                        class="btn btn-info"
                        data-dismiss="modal"
                        @click="RegSetupPhoto"
                      >
                        사진 재등록
                      </button>
                    </div>
                    <div class="col-sm" style="text-align: right">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        닫기
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 개인정보동의화면 confirm -->
        <div
          class="modal fade"
          id="agreeModal"
          tabindex="-1"
          aria-labelledby="agreeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title" id="agreeModalLabel">
                  [ 개인정보 수집 및 이용 동의서 ]
                </h3>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="scroll-container" style="background: lightgray">
                  <div class="text">
                    동안교회 교육부는 「개인정보보호법」 등 관련 법령에 따라
                    교회 회원으로 개인정보보호에 최선을 다하고자 합니다.
                    정보주체로부터 개인정보를 수집함에 있어, 아래 내용을
                    안내하고 있으니 아래 내용을 상세히 읽어보신 후에 동의 여부를
                    결정하여 주시기 바랍니다.
                    <br /><br />
                    ※ 개인정보 수집 및 이용
                    <br />
                    1) 개인정보의 수집 이용 목적
                    <br />
                    - 교회 행정 및 목양
                    <br />
                    - 교육부 만나 앱 이용
                    <br />
                    2) 수집하는 개인정보의 항목
                    <br />
                    - 성명, 성별, 생년월일, 주소, 연락처, 학교, 사진
                    <br />
                    3) 개인정보의 보유 및 이용 기간
                    <br />
                    - 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를
                    지체 없이 파기합니다.
                    <br />
                    4) 개인정보의 파기 절차 및 방법
                    <br />
                    - 회원 탈퇴(교회 이적)시 본인 확인 후 개인정보를 지체 없이
                    파기합니다.
                    <br />
                    - 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기하며,
                    전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                    기술적 방법을 사용하여 삭제합니다.
                    <br />
                    위와 같이 본인의 개인정보가 수집 및 이용되는 것에 대하여
                    동의합니다.
                    <br />
                    [개인정보 수집 및 이용에 관한 동의를 거부할 권리가 있으며,
                    동의 거부시 회원 가입 또는 서비스 이용이 제한될 수
                    있습니다.]
                    <br />
                  </div>
                </div>
                <p></p>
                <form id="agreePrivateForm">
                  <div class="container">
                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-3" style="text-align: right">
                        <label style="font-weight: bold">성명:</label>
                      </div>
                      <div class="col-9">
                        <input
                          type="text"
                          class="form-control"
                          id="agree_name"
                          name="agree_name"
                          :value="itempopup.name"
                          style="color: blue"
                        />
                      </div>
                    </div>

                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-3" style="text-align: right">
                        <label style="font-weight: bold">생년월일:</label>
                      </div>
                      <div class="col-9">
                        <input
                          type="text"
                          class="form-control"
                          id="agree_birthday"
                          name="agree_birthday"
                          :value="itempopup.birthday"
                          placeholder="ex) 20070423"
                          style="color: blue"
                        />
                      </div>
                    </div>

                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-3" style="text-align: right">
                        <label style="font-weight: bold; text-align: right"
                          >연락처:</label
                        >
                      </div>
                      <div class="col-9" style="padding-bottom: 10px">
                        <input
                          type="text"
                          class="form-control"
                          id="agree_phone"
                          name="agree_phone"
                          :value="itempopup.phone"
                          placeholder="ex) 01012345678"
                          style="color: blue"
                        />
                      </div>
                    </div>

                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-6" style="text-align: right">
                        <button
                          type="button"
                          class="btn btn-dark"
                          @click="nextagree"
                        >
                          본인이 아닙니다.
                        </button>
                      </div>
                      <div class="col-6" style="text-align: right">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="agreeform"
                        >
                          본인이며, 수집 및 이용에 동의합니다.
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 사진 -->
        <div
          class="modal fade"
          id="takePhotoModal"
          tabindex="-1"
          aria-labelledby="takePhotoModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <div class="row">
                  <div class="col-12">
                    <h3 class="modal-title" id="takePhotoModalLabel">
                      [ 출석부 사진 등록 ]
                    </h3>
                  </div>
                  <div class="col-12">
                    <h6>
                      <font color="green">[촬영 버튼]</font>을 누르고
                      <font color="red">정면 카메라</font>를 보면 2초후
                      촬영됩니다.
                    </h6>
                  </div>
                </div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"></span>
                </button>
              </div>
              <div class="modal-body">
                <p></p>

                <RadialProgress
                  :diameter="200"
                  :completed-steps="completedSteps"
                  :total-steps="totalSteps"
                  startColor="#3880ff"
                  stopColor="#3880ff"
                  innerStrokeColor="lightgray"
                  :stroke-width="strokeWidth"
                  :inner-stroke-width="innerStrokeWidth"
                  v-if="countview"
                >
                  <div
                    class="circle_progress_info"
                    style="text-align: center; font-size: 100px"
                  >
                    <span>{{ completedSteps }}</span>
                  </div>
                </RadialProgress>
                <div
                  v-if="!countview && photoDataUrl == null"
                  style="
                    text-align: center;
                    font-size: 17px;
                    color: red;
                    font-weight: 500;
                  "
                >
                  <img src="../assets/duda.jpg" width="50" />얼굴을 카메라에
                  아주 가깝게 대고 활짝 웃어주세요.
                </div>
                <div v-if="photoDataUrl !== null">
                  <img :src="photoDataUrl" alt="사진" />
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-sm" style="padding-top: 20px">
                      <button
                        type="button"
                        class="btn btn-success btn-lg btn-block"
                        style="margin-bottom: 10px"
                        @click="takePhoto"
                      >
                        {{ btnTitle }}
                      </button>
                    </div>
                    <div class="col-sm" style="padding-top: 20px">
                      <button
                        type="button"
                        class="btn btn-primary btn-lg btn-block"
                        style="margin-bottom: 10px"
                        @click="savePhoto()"
                        :disabled="saveAble"
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </div>

                <form id="takePhotoModalForm">
                  <div class="container">
                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-3" style="text-align: right"></div>
                      <div class="col-9"></div>
                    </div>

                    <div class="row" style="padding-bottom: 10px">
                      <div class="col-12" style="text-align: right"></div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="takePhotoClolse"
                  data-dismiss="modal"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 사진 -->
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
  import manageService from "../services/manageService";
  import RadialProgress from "vue3-radial-progress";
  import { ref } from "vue";
  import $ from "jquery";
  export default {
    name: "list",
    component: { RadialProgress },
    data() {
      return {
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: this.$store.state.role,
        token: this.$store.state.token,
        classid: JSON.parse(localStorage.getItem("userData")).classid,
        gradegrouplist: {},
        items: [],
        manageLists: {},
        studentlist: [],
        studentid: JSON.parse(localStorage.getItem("userData")).studentid,
        imageyn: false,
        personView: false,
        selectedGrade: null, // 현재 선택된 grade를 추적하기 위한 속성
        today: "",
        holiday: this.today,
        dayLists: {},
        attendyn: "N",
        cancelAttendyn: false,
        confirmModal: false,
        itempopup: {},
        nlists: {},
        photoDataUrl: null,
        btnTitle: "촬영",
        saveAble: true,
        takephotoimageurl: "",
        countview: false,
      };
    },
    setup() {
      const completedSteps = ref(2);
      const totalSteps = ref(0);
      const strokeWidth = ref(8);
      const innerStrokeWidth = ref(8);

      return {
        completedSteps,
        totalSteps,
        strokeWidth,
        innerStrokeWidth,
      };
    },
    computed: {
      photourl() {
        return (url) => {
          return "./upload/photo/" + url;
        };
      },
      imageStyle() {
        return {
          width: `${this.imageWidth}px`,
          border: "none",
        };
      },
      profileimageStyle() {
        return {
          width: `${this.profileimgWidth}px`,
          border: "none",
        };
      },
      totalPages() {
        return Math.ceil(this.totalItems / this.limit);
      },
      pageNumbers() {
        const numbers = [];
        for (let i = 1; i <= this.totalPages; i++) {
          numbers.push(i);
        }
        return numbers;
      },
    },
    methods: {
      nextagree() {
        this.itempopup.agreeyn = 1;
        $("#agreeModal").modal("hide");
        $("#confrimModal").modal();
      },
      async agreeform() {
        var object = {};
        var nform = document.getElementById("agreePrivateForm");
        var vform = new FormData(nform);
        vform.forEach(function (value, key) {
          object[key] = value;
          //console.log(key, value);
        });

        if (
          object["agree_name"] == "" ||
          object["agree_birthday"] == "" ||
          object["agree_phone"] == ""
        ) {
          this.$toast.show("내용을 모두 입력해야 합니다.", {
            type: "warning",
            position: "top-left",
          });
          return;
        }

        object["studentid"] = this.itempopup.studentid;

        var jsondata = JSON.stringify(object);

        await manageService
          .setPrivateInfoAgree(jsondata)
          .then((response) => {
            if (response.data) {
              this.$toast.show("동의 처리가 되었습니다.", {
                type: "success",
                position: "top-left",
              });

              this.itempopup.agreeyn = 1;

              $("#agreeModal").modal("hide");

              if (
                this.itempopup.photo == null &&
                this.itempopup.photo2 == null
              ) {
                // 사진이 없으면 사진부터 찍고 갑니다.
                $("#takePhotoModal").modal();
              } else {
                $("#confrimModal").modal();
              }
            } else {
              this.$toast.show("다시 시도해주세요.", {
                type: "warning",
                position: "top-left",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      RegSetupPhoto() {
        //사진 재등록 버튼
        $("#takePhotoModal").modal();
      },
      showModal(item) {
        this.completedSteps = 2;
        //this.countview = true;
        this.saveAble = true;
        this.btnTitle = "촬영";
        this.photoDataUrl = null;

        this.itempopup = item;
        console.log("item.photo==", item.photo);
        console.log("item.photo2==", item.photo2);

        //동의가 되었으면 바로 출석체크 , 아니면 동의 화면으로 이동함
        if (this.itempopup.classid == "3") {
          // 청3은 동의를 먼저 받는다.
          if (this.itempopup.agreeyn == "1") {
            $("#confrimModal").modal(); // 출석
          } else {
            // 동의가 안되어 있으면 먼저 동의를 거쳐야 합니다.
            $("#agreeModal").modal(); //동의
          }
        } else {
          if (this.itempopup.agreeyn == "1") {
            //동의가 되었으면 사진만 찍으면 됩니다.

            //사진이 없으면 사진창을 띄운다
            if (item.photo == null && item.photo2 == null) {
              // 사진
              $("#takePhotoModal").modal();
            } else {
              $("#confrimModal").modal(); // 출석
              //$("#agreeModal").modal(); //동의
            }
          } else {
            // 동의가 안되어 있으면 먼저 동의를 거쳐야 합니다.
            $("#agreeModal").modal(); //동의
          }
        }

        // $("#confrimModal").modal();
      },

      async getGradeGroupList() {
        const ndata = {
          classid: this.classid,
        };
        await manageService
          .getGradeListAll(ndata)
          .then((response) => {
            //this.manageLists = response.data;
            this.gradegrouplist = response.data;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      //전체 리스트 가져오기
      async getMgmtAllList(item, v) {
        this.manageLists = {};

        if (this.holiday === "" || this.holiday === "00" ) {
          this.$toast.show("날짜를 먼저 선택해주세요.", {
            type: "error",
            position: "top-left",
          });
          return;
        }

        //console.log("holiday ==>", this.holiday);

        if (v == "1") this.personView = false;
        else this.personView = true;

        //선택 바 표시
        this.selectedGrade = item;

        const idata = {
          sector: "30",
          grade: item,
          teacherid: "",
          classid: this.classid,
          today: this.holiday,
        };

        await manageService
          .getAllList(idata)
          .then((response) => {
            this.manageLists = response.data;
            //console.log("manageLists == ",  this.manageLists);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      async getGradeStudent(xdata) {
        $("#confrimModal").modal("hide");

        //console.log("getGradeStudent xdata == ",  xdata);
        this.cancelAttendyn == false;

        this.attendyn = xdata.attendyn;

        const idata = {
          studentno: xdata.studentid,
          classid: this.classid,
        };

        await manageService
          .getStudentList(idata)
          .then((response) => {
            this.studentlist = response.data[0];
            this.personView = true;
            //console.log("getStudentList == ",  this.studentlist);

            //취소 한 경우 버튼 블럭색을 없애야만 한다.
            if (this.studentlist.attendyn === "N") {
              this.cancelAttendyn = true;
              this.attendyn = "N";
            }
            //출석 체크 로직
            this.attendcheck(xdata, 1);

            // 3초 후에 personView를 false로 설정
            setTimeout(() => {
              this.personView = false;
            }, 5000);
          })
          .catch((e) => {
            console.log(e);
          });

        console.log(idata);
      },
      getDateFormat() {
        // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
        var js_date = new Date();

        // 연도, 월, 일 추출
        var year = js_date.getFullYear();
        var month = js_date.getMonth() + 1;
        var day = js_date.getDate();

        // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }
        this.holiday = year + "-" + month + "-" + day;

        //return moment(String(ddate)).format("YYYYMMDD");
        return this.holiday;
      },
      async getDayList() {
        await manageService
          .getDaysList()
          .then((response) => {
            this.dayLists = response.data.slice(0, this.page);
            console.log("this.dayLists ==", this.dayLists);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      async attendcheck(item, sector) {
        //출석 체크

        let temp = "";

        if (item.attendyn == "Y") temp = "N";
        else temp = "Y";
        //출석체크
        const data = {
          classid: item.classid,
          studentid: item.studentid,
          grade: item.grade,
          sector: sector,
          holiday: this.holiday,
          teacherid: item.teacherid,
          groupid: item.groupid,
          checkyn: temp,
          gradename: item.gradename,
          name: item.name,
          pray: item.pray,
        };
        //console.log("data =",  data);
        await manageService
          .setAttendChech(data)
          .then(() => {
            if (temp === "Y") {
              this.$toast.show("출석처리되었습니다.", {
                type: "success",
                position: "top-left",
              });
            } else {
              this.$toast.show("출석이 취소되었습니다.", {
                type: "info",
                position: "top-left",
              });
              this.cancelAttendyn == true;
            }
            this.getMgmtAllList(item.grade, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      getData() {
        const idata = {
          category: "3",
          classid: this.classid,
        };
        manageService
          .getboardboardall(idata)
          .then((response) => {
            this.nlists = response.data;
            console.log("nlists == ", this.nlists);
            //this.slists = response.data.study;
            //this.manageLists = response.data.slice(0, this.page);
            //console.log( this.nearMember);
            //this.manageLists = response.data;
            //this.manageListLimit = response.data;
            //this.pageTotalCount = Math.ceil(response.data.length / this.page);
          })
          .catch((e) => {
            console.log(e);
          });
      },
      async takePhoto() {
        this.countview = true;

        this.$toast.show("앞에 카메라 렌즈를 보세요.", {
          type: "info",
          position: "top-left",
        });

        if (this.btnTitle == "재촬영") {
          console.log(this.btnTitle);
          this.completedSteps = 2;
          this.countview = true;
          this.photoDataUrl = null;
        }

        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: "user" },
          });
          const video = document.createElement("video");
          video.srcObject = stream;
          video.play();

          this.startCountdown(); //카운트 표시

          setTimeout(() => {
            // Canvas 생성
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext("2d");

            // 비디오의 현재 프레임을 캔버스에 그리기
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // 캔버스 데이터를 Blob으로 변환
            canvas.toBlob(async (blob) => {
              // Blob을 FormData에 추가
              const formData = new FormData();
              formData.append("image", blob);

              // 이미지를 서버에 전송
              //const response = await axios.post('abc.php', formData);

              await manageService
                .savephoto(formData)
                .then((response) => {
                  console.log("사진이 서버에 저장되었습니다.", response.data);
                  this.takephotoimageurl = response.data;
                  this.saveAble = false;
                  this.btnTitle = "재촬영";
                  this.$toast.show(
                    "촬영되었습니다. [저장] 버튼을 눌러주세요.",
                    {
                      type: "success",
                      position: "top-left",
                    }
                  );

                  //this.manageLists = response.data;
                  //this.gradegrouplist = response.data;
                })
                .catch((e) => {
                  console.log(e);
                });

              //console.log('사진이 서버에 저장되었습니다.', response.data);

              // 사용이 끝난 스트림 및 비디오를 해제
              stream.getTracks().forEach((track) => track.stop());
              video.srcObject = null;
            }, "image/jpeg");

            // 캔버스 데이터를 이미지 URL로 변환하여 화면에 표시
            this.photoDataUrl = canvas.toDataURL("image/jpeg");
          }, 2000);
        } catch (error) {
          console.error("사진을 찍는 도중 오류가 발생했습니다.", error);
        }
      },
      startCountdown() {
        const interval = setInterval(() => {
          if (this.completedSteps > 1) {
            this.count--;
            this.completedSteps--;
          } else {
            this.countview = false;
            clearInterval(interval);
          }
        }, 1000); // 1초마다 카운트 감소
      },
      async savePhoto() {
        try {
          // 이미지 데이터를 서버에 전송

          const ndata = {
            image: this.takephotoimageurl,
            studentid: this.itempopup.studentid,
          };
          await manageService
            .savephotoimage(ndata)
            .then((response) => {
              console.log(response);
              console.log(
                "사진이 서버에 저장되었습니다.",
                this.takephotoimageurl
              );
              this.itempopup.photo = this.takephotoimageurl;
              this.itempopup.photo2 = this.takephotoimageurl;

              const myImage1 = document.getElementById("myImage1");
              const myImage2 = document.getElementById("myImage2");

              // 이미지의 src 속성 변경
              myImage1.src =
                "https://manna.dongan1718.com/upload/photo/" +
                this.takephotoimageurl;
              myImage2.src =
                "https://manna.dongan1718.com/upload/photo/" +
                this.takephotoimageurl;

              this.$toast.show("저장되었습니다.", {
                type: "success",
                position: "top-left",
              });

              $("#takePhotoModal").modal("hide");
              $("#confrimModal").modal(); // 출석

              //this.manageLists = response.data;
              //this.gradegrouplist = response.data;
            })
            .catch((e) => {
              console.log(e);
            });

          // 이미지 저장 후에는 이미지 데이터 초기화
          this.photoDataUrl = null;
        } catch (error) {
          console.error(
            "사진을 서버에 저장하는 도중 오류가 발생했습니다.",
            error
          );
        }
      },
      takePhotoClolse() {
        //사진 모달창 닫기
        $("#takePhotoModal").modal("hide");
        this.btnTitle = "촬영";
        this.saveAble = true;
        this.photoDataUrl = null;
        this.completedSteps = 3;
      },
    },
    mounted() {
      //this.getTotalAttend(); //전체 출석
      this.getDateFormat(); //날짜
      this.getDayList();
      this.getGradeGroupList();
      this.getData();

      // this.confirmModal = new Modal(this.$refs.confirmModal, {
      //   keyboard: false
      // });
    },
  };
</script>

<style scoped>
  .btn-primary {
    /* 활성 상태 버튼의 스타일 */
    background: #007bff;
    color: white;
  }
  .list {
    text-align: left;
    max-width: 750px;
    margin: auto;
  }
  .done {
    background: oldlace;
  }
  .card-body {
    padding: 0.5rem;
  }
  .table td,
  .table th {
    padding: 0.2rem;
    vertical-align: middle;
  }
  @media (max-width: 600px) {
    .image-container img {
      width: 25px;
    }
    .image-profilecontainer img {
      width: 25px;
    }
  }
  .attendance-container {
    display: flex; /* Flexbox를 활성화 */
    align-items: center; /* 아이템들을 세로 중앙에 정렬 */
    margin-top: 30px; /* 상단 여백 */
    margin-bottom: 30px;
  }

  .attendance-status,
  .attendance-select {
    margin-right: 20px; /* 오른쪽 여백 */
  }

  .form-control {
    width: 140px; /* select의 너비 설정 */
  }
  .scroll-container {
    height: 200px; /* 스크롤 가능한 영역의 높이를 설정합니다. */
    overflow-y: auto; /* 세로 스크롤을 활성화합니다. */
  }

  .text {
    margin: 10px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
</style>
